<template>
  <div class="row mt-2">
    <ParticipantGrowthTemplate
        :loaded="loaded"
        :legend-title="legendTitle"
        :report-data="participantGrowthData"
        :show-esu-count="true"
    />
  </div>
</template>

<script>
import {useStore} from "vuex";
import {ref, onBeforeMount, watch, computed} from "vue";
import axios from "axios";
import {participationByStateInRange} from "@/Helpers/ApiCalls/ReportAPICalls";
import {isEmpty} from "lodash/lang";
import ParticipantGrowthTemplate from "@/views/SuperUserViews/Reporting/ParticipantGrowth/ParticipantGrowthTemplate";

export default {
  name: "StateParticipantGrowth",
  components: {
    ParticipantGrowthTemplate
  },
  props: {
    stateShort: String,
    stateName: String,
    startDate: Date,
    endDate: Date
  },
  setup(props, context) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const participantGrowthData = ref({});
    const loaded = ref(false);

    onBeforeMount(() => {
      getParticipantData();
    })

    watch(() => props.stateShort, (first, second) => {
      getParticipantData();
    })

    watch(() => props.startDate, (first, second) => {
      getParticipantData();
    })
    watch(() => props.endDate, (first, second) => {
      getParticipantData();
    })

    async function getParticipantData() {
      loaded.value = false;
      let formatted_start = props.startDate.toISOString().slice(0, 10);
      let formatted_end = props.endDate.toISOString().slice(0, 10);
      let state_short = props.stateShort;
      await axios.get(participationByStateInRange(), {
        headers: {
          "Authorization": "Bearer " + authToken.value
        },
        params: {
          _stateShort: state_short,
          _startDate: formatted_start,
          _endDate: formatted_end,
        }
      }).then((result) => {
        participantGrowthData.value = result.data;
      }).finally(() => {
        if (!isEmpty(participantGrowthData.value)) {
          loaded.value = true;
        }
      })
    }


    const legendTitle = computed(() => {
      return "Participant Growth for " + props.stateName
    })

    return {
      participantGrowthData,
      loaded,
      legendTitle,
    }
  }
}
</script>

<style scoped>

</style>