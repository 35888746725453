<template>
  <div class="row mt-2">
    <ParticipantGrowthTemplate
      :report-data="countryData"
      :legend-title="legendTitle"
      :loaded="loaded"
      :show-esu-count="true"
    />
  </div>
</template>

<script>
import {ref, onBeforeMount, computed, watch} from "vue";
import axios from "axios";
import ParticipantGrowthTemplate from "@/views/SuperUserViews/Reporting/ParticipantGrowth/ParticipantGrowthTemplate";
import {useStore} from "vuex";
import {participationByCountryInRange} from "@/Helpers/ApiCalls/ReportAPICalls";

export default {
  name: "CountryParticipantGrowth",
  components: {ParticipantGrowthTemplate},
  props: {
    countryCode: String,
    countryName: String,
    startDate: Date,
    endDate: Date
  },
  setup(props, context) {
    const store = useStore()
    const authToken = store.getters['auth/authenticated'];
    const countryData = ref({});
    const loaded = ref(false);

    onBeforeMount(() => {
      getCountryData();
    })

    watch(() => props.countryCode, (first, second) => {
      getCountryData();
    })

    watch(() => props.startDate, (first, second) => {
      getCountryData();
    })

    watch(() => props.endDate, (first, second) => {
      getCountryData();
    })

    async function getCountryData() {
      loaded.value = false;
      let formatted_start = props.startDate.toISOString().slice(0, 10);
      let formatted_end = props.endDate.toISOString().slice(0, 10);
      await axios.get(participationByCountryInRange(), {
        params: {
          _startDate: formatted_start,
          _endDate: formatted_end,
          _countryCode: props.countryCode
        },
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        countryData.value = result.data;
      }).finally(() => {
        loaded.value = true;
      })
    }

    const legendTitle = computed(() => {
      return "Participant Growth for " + props.countryName;
    })

    return {
      countryData,
      legendTitle,
      loaded
    }
  }

}
</script>

<style scoped>

</style>