<template>
  <div class="row mt-2">
    <ParticipantGrowthTemplate
        :report-data="schoolData"
        :legend-title="legendTitle"
        :loaded="loaded"
        :show-esu-count="loaded"
    />
  </div>
</template>

<script>
import {ref, computed, watch, onBeforeMount} from "vue";
import ParticipantGrowthTemplate from "@/views/SuperUserViews/Reporting/ParticipantGrowth/ParticipantGrowthTemplate";
import {useStore} from "vuex";
import {participationBySchoolInRange} from "@/Helpers/ApiCalls/ReportAPICalls";
import axios from 'axios';
import {isEmpty} from "lodash/lang";

export default {
  name: "SchoolParticipantGrowth",
  components: {ParticipantGrowthTemplate},
  props: {
    idSchool: Number,
    startDate: Date,
    endDate: Date
  },
  setup(props, context) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const schoolData = ref({});
    const loaded = ref(false);

    onBeforeMount(() => {
      getSchoolData();
    })

    watch(() => props.idSchool, (first, second) => {
      getSchoolData();
    })

    watch(() => props.startDate, (first, second) => {
      getSchoolData();
    })

    watch(() => props.endDate, (first, second) => {
      getSchoolData();
    })

    async function getSchoolData() {
      loaded.value = false;
      let formatted_start = props.startDate.toISOString().slice(0, 10);
      let formatted_end = props.endDate.toISOString().slice(0, 10);
      let get_uri = participationBySchoolInRange() + props.idSchool;
      await axios.get(get_uri, {
        params: {
          _startDate: formatted_start,
          _endDate: formatted_end
        },
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        schoolData.value = result.data;
      }).finally(() => {
        if (!isEmpty(schoolData.value)) {
          loaded.value = true;
        }
      })
    }

    const legendTitle = computed(() => {
      return schoolData.value.legendTitle;
    })

    return {
      schoolData,
      legendTitle,
      loaded
    }
  }
}
</script>

<style scoped>

</style>