<template>
  <div class="container-fluid"
       style="border-width: 1px; border-color: black; border-radius: 1px; height: auto; width: 600px; margin: 20px" v-if="loaded">
    <div class="row" style="padding: 1%;">
      <h5 style="color: black;">{{ legendTitle }}</h5>
      <hr/>
    </div>
    <div class="row" style="height: auto; width: auto;" v-if="loaded">

      <div class="row">
        <div class="col">
          <Line
              :chart-data="chartData"
              :css-classes="cssClasses"
              :chart-id="chartId"
              :styles="styles"
              :dataset-id-key="datasetIdKey"
              :chart-options="chartOptions"
              :width="1500"
              :height="625"
              :plugins="plugins"
          />
        </div>
        <div class="col">
          <b-tooltip target="_self" title="Download this growth data">
            <button type="submit" class="button btn-primary" @click="downloadReport">Download</button>
          </b-tooltip>
        </div>
      </div>

    </div>

  </div>
  <div class="row mt-2" v-if="showLoadingSpinner">
    <Loader/>
  </div>
  <div class="row mt-2" v-else-if="showNoDataMessage">
    <span>No Growth Data was found in that range</span>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {Line} from 'vue-chartjs';
import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from 'chart.js'
import {exportCsvWithRows} from "@/ExportCsv";
import {isEmpty} from "lodash/lang";
import Loader from "@/components/Loader";


ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale)

export default {
  name: "ParticipantGrowthTemplate",
  components: {Loader, Line},
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      style: {
        position: 'relative',
        height: 'auto',
        width: 'auto',
      }
    },
    legendTitle: {
      default: 'Participant Growth',
      type: String
    },
    reportData: Object,
    loaded: Boolean,
    showEsuCount: Boolean
  },
  setup(props, context) {
    const startDate = ref(new Date());
    const endDate = ref(new Date());
    const showEndSelect = ref(false);
    const chartData = ref({})
    const chartOptions = ref({
      type: 'line',
      scales: {
        yAxes: {
          title: {
            display: true,
            text: 'Registered Count',
            font: {
              size: 15
            }
          },
          ticks: {
            precision: 0
          },
          beginAtZero: true,
          grace: '10%' // grace above highest point on graph, this may need to be tweaked when we get to production data and see what it looks like
        },
        xAxes: {
          title: {
            display: true,
            text: 'Date',
            font: {
              size: 15
            }
          }
        }
      }
    })

    onBeforeMount(() => {
      if (props.reportData !== null && props.reportData !== undefined && !isEmpty(props.reportData)) {
        prepareDataSets();
      }
    })

    watch(() => props.reportData, (first, second) => {
      prepareDataSets();
    })

    const plugins = ref({
      legend: {
        display: true,
        text: props.legendTitle,
        position: 'right',
        labels: {
          color: 'rgb(0, 0, 0)'
        }
      }
    })

    const minEndDate = computed(() => {
      let min_date = new Date(startDate.value.toDateString());
      let result = min_date.setDate(min_date.getDate() + 1);
      return new Date(result);
    })


    function prepareDataSets() {
      chartData.value = props.reportData;
      for (let i = 0; i < chartData.value.datasets.length; i++) {
        chartData.value.datasets[i].borderColor = ['#' + Math.floor(Math.random() * 16777215).toString(16)]
      }
    }

    function downloadReport() {
      let headers = [""].concat(chartData.value.labels);
      const date = new Date();

      let rows = [];

      for (let i = 0; i < chartData.value.datasets.length; i++) {
        rows[i] = [chartData.value.datasets[i].label].concat(chartData.value.datasets[i].data);
      }

      const formatted_date = [date.getMonth() + 1, date.getDate(), date.getFullYear()].join('_');
      let file_name = props.legendTitle + "_" + formatted_date + '.csv';
      exportCsvWithRows(headers, rows, file_name);
    }

    const showLoadingSpinner = computed(() => {
      return props.loaded && isEmpty(props.reportData);
    })

    const showNoDataMessage = computed(() => {
      return isEmpty(props.reportData);
    })

    return {
      chartData,
      chartOptions,
      plugins,
      startDate,
      endDate,
      showEndSelect,
      minEndDate,
      showLoadingSpinner,
      showNoDataMessage,
      downloadReport
    }
  }
}
</script>

<style scoped>

</style>