<template>
  <div class="row mt-2">
    <ParticipantGrowthTemplate
        :loaded="loaded"
        :report-data="esuData"
        :show-esu-count="false"
        :legend-title="legendTitle"/>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {ref, onBeforeMount, watch, computed} from "vue";
import {participationByEsuInRange} from "@/Helpers/ApiCalls/ReportAPICalls";
import axios from 'axios'
import {isEmpty} from "lodash/lang";
import ParticipantGrowthTemplate from "@/views/SuperUserViews/Reporting/ParticipantGrowth/ParticipantGrowthTemplate";

export default {
  name: "EsuParticipantGrowth",
  components: {ParticipantGrowthTemplate},
  props: {
    idEsu: Number,
    startDate: Date,
    endDate: Date
  },
  setup(props, context) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const esuData = ref({});
    const loaded = ref(false);

    onBeforeMount(() => {
      getEsuData();
    })

    watch(() => props.idEsu, (first, second) => {
      getEsuData();
    })

    watch(() => props.startDate, (first, second) => {
      getEsuData();
    })

    watch(() => props.endDate, (first, second) => {
      getEsuData();
    })

    async function getEsuData() {
      loaded.value = false;
      let formatted_start = props.startDate.toISOString().slice(0, 10);
      let formatted_end = props.endDate.toISOString().slice(0, 10);
      let get_uri = participationByEsuInRange() + props.idEsu;
      await axios.get(get_uri, {
        params: {
          _startDate: formatted_start,
          _endDate: formatted_end,
        },
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        esuData.value = result.data;
      }).finally(() => {
        if (!isEmpty(esuData.value)) {
          loaded.value = true;
        }
      })
    }

    const legendTitle = computed(() => {
      return "Participant Growth for ESU #" + props.idEsu
    })

    return {
      esuData,
      loaded,
      legendTitle
    }
  }
}
</script>

<style scoped>

</style>