<template>
  <div class="row mt-2">
    <ParticipantGrowthTemplate
        :report-data="districtData"
        :legend-title="legendTitle"
        :loaded="loaded"
        :show-esu-count="false"
    />
  </div>
</template>

<script>
import axios from "axios";
import {computed, onBeforeMount, ref, watch} from "vue";
import {nebraskaByDistrictParticipationInRange} from "@/Helpers/ApiCalls/ReportAPICalls";
import {useStore} from "vuex";
import ParticipantGrowthTemplate from "@/views/SuperUserViews/Reporting/ParticipantGrowth/ParticipantGrowthTemplate";
import {isEmpty} from "lodash/lang";

export default {
  name: "DistrictParticipantGrowth",
  components: {ParticipantGrowthTemplate},
  props: {
    idDistrict: Number,
    startDate: Date,
    endDate: Date
  },
  setup(props, context) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const districtData = ref({});
    const loaded = ref(false);

    const load = () => {
      loaded.value = true;
      getDistrictData();
    }

    onBeforeMount(() => {
      getDistrictData();
    })

    watch(() => props.idDistrict, (first, second) => {
      getDistrictData();
    })

    watch(() => props.startDate, (first, second) => {
      getDistrictData();
    })

    watch(() => props.endDate, (first, second) => {
      getDistrictData();
    })



    async function getDistrictData() {
      let formatted_start = props.startDate.toISOString().slice(0, 10);
      let formatted_end = props.endDate.toISOString().slice(0, 10);
      let get_uri = nebraskaByDistrictParticipationInRange() + props.idDistrict;
      await axios.get(get_uri, {
        params: {
          _startDate: formatted_start,
          _endDate: formatted_end
        },
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        districtData.value = result.data;
      }).finally(() => {
        if (!isEmpty(districtData.value)) {
          loaded.value = true;
        }
      })
    }

    const legendTitle = computed(() => {
      return districtData.value.legendTitle;
    })

    return {
      districtData,
      legendTitle,
      loaded,
      load,
    }
  }
}
</script>

<style scoped>

</style>