<template>
  <label>District</label>
  <select class="form-select" v-model="selectedDistrict" @change="changeDistrict($event)" :disabled="selectDisabled">
    <option :value="-1">Select a District</option>
    <option v-for="district in districts" :key="district.idDistrict" :value="district.idDistrict">
      {{ district.districtName }}
    </option>
  </select>


</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import axios from 'axios';
import {API_URL} from "../../../../Constants";
import ParticipatingSchools from "@/components/Reporting/OrganizationDropdowns/ParticipatingSchools";

export default {
  name: "ParticipatingDistricts",
  props: {
    idEsu: Number,
    idDistrict: Number,
    selectDisabled: Boolean
  },
  setup(props, context) {
    const districts = ref([]);
    const selectedDistrict = ref(-1);

    onBeforeMount(() => {
      getDistrictsByEsu();
      if (props.idDistrict) selectedDistrict.value = props.idDistrict;
    })

    watch(() => props.idEsu, (first, second) => {
      getDistrictsByEsu();
      selectedDistrict.value = -1;
    })

    const districtCount = computed(() => {
      return districts.value.length;
    })


    async function getDistrictsByEsu() {
      let get_uri = API_URL + "/district/participating/byEsu?_idEsu=" + props.idEsu;
      await axios.get(get_uri)
          .then((result) => {
            districts.value = result.data;
          }).finally(() => {
            countEmit();
          })
    }

    function countEmit() {
      context.emit("districtCount", districtCount.value);
    }

    function changeDistrict(event){
      selectedDistrict.value = event.target.value;
      context.emit("selectedDistrict", selectedDistrict.value);
    }

    watch(() => props.idDistrict, () => {
      selectedDistrict.value = props.idDistrict;
    })

    return {
      districts,
      selectedDistrict,
      changeDistrict
    }
  }
}
</script>

<style scoped>

</style>