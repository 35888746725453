<template>
    <label>ESU</label>
    <select class="form-select" v-model="selectedEsu" @change="changeEsu($event)" :disabled="selectDisabled">
      <option :value="-1">Select an ESU</option>
      <option v-for="esu in esus" :key="esu.idEsu" :value="esu.idEsu">{{ esu.esuName }}</option>
    </select>

</template>

<script>
import ParticipatingSchools from "@/components/Reporting/OrganizationDropdowns/ParticipatingSchools";
import {computed, onBeforeMount, ref, watch} from "vue";
import {API_URL} from "../../../../Constants";
import axios from "axios";

export default {
  name: "ParticipatingEsus",
  props: {
    filterByDistrict: Boolean,
    idEsu: Number,
    selectDisabled: Boolean
  },
  setup(props, context) {
    const esus = ref([]);
    const selectedEsu = ref(-1);
    const showDistrictDropdown = ref(false);


    onBeforeMount(() => {
      getParticipatingEsus();
      if (props.idEsu) selectedEsu.value = props.idEsu;
    })

    watch(() => props.filterByDistrict, (first, second) => {
      showDistrictDropdown.value = props.filterByDistrict;
    })

    const esuCount = computed(() => {
      return esus.value.length;
    })

    async function getParticipatingEsus() {
      let get_uri = API_URL + "/esu/participating";
      await axios.get(get_uri)
          .then((result) => {
            esus.value = result.data;
          }).finally(() => {
            emitCount();
          })
    }

    function changeEsu(event){
      selectedEsu.value = event.target.value;
      context.emit("selectedEsu", selectedEsu.value);
    }

    function emitCount() {
      context.emit("esuCount", esuCount.value);
    }

    watch(() => props.idEsu, () => {
      selectedEsu.value = props.idEsu;
    })

    return {
      esus,
      selectedEsu,
      showDistrictDropdown,
      changeEsu
    }
  }
}
</script>

<style scoped>

</style>