export const exportCsv = (array, fileName) => {
    const csv_content = array.map((row) => row.join(","))
        .join("\n");

    const blob = new Blob([csv_content], {
        type: 'text/csv;charset=utf-8;'
    })

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
}

// export const exportCsv = (headers, array, fileName) => {
//     let csv = headers;
//     csv += "\n";
//
//     array.forEach((row) => {
//         csv += row.join(',');
//         csv += ("\n");
//     })
//
//     let hidden_element = document.createElement('a');
//     hidden_element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
//     hidden_element.download = fileName;
//
//     hidden_element.click();
// }

export const exportCsvWithRows = (headers, rows, fileName) => {
    let csv = headers;

    for (let i = 0; i < rows.length; i++) {
        csv += ("\n");
        csv += rows[i];
    }

    let hidden_element = document.createElement('a');
    hidden_element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hidden_element.download = fileName;

    hidden_element.click();
}