<template>
  <div class="container-fluid">
    <div class="row" style="border-bottom: thin solid black; padding: 1%;">
      <h4 style="color: black;">Organization Hierarchy</h4>

      <div class="col-sm">
        <ParticipatingCountries
            @countryCode="changeCountry($event)"
            @countryName="updateCountryName($event)"
            :show-all-select-value="true"
        />
      </div>
      <div class="col-sm">
        <div v-if="selectedCountry === 'US'">
          <ParticipatingStates
              @selectedState="changeState($event)"
              @stateName="updateStateName($event)"
          />
        </div>
      </div>
      <div class="col-sm">
        <div v-if="showNebraskaHierarchy">
          <ParticipatingEsus @selectedEsu="changeEsu($event)" @esuCount="updateEsuCount($event)"/>
          <!--        <h6>Participating ESU Count: {{ esuCount }}</h6>-->
        </div>
      </div>
      <div class="col-sm">
        <div v-if="showNebraskaHierarchy">
          <ParticipatingDistricts :id-esu="selectedEsu" @selectedDistrict="changeDistrict($event)"
                                  @districtCount="updateDistrictCount($event)"/>
          <!--        <h6>Participating District Count: {{ districtCount }}</h6>-->
        </div>
      </div>
      <div class="col-sm">
        <div v-if="showNebraskaHierarchy">
          <ParticipatingSchools :id-esu="selectedEsu" :id-district="selectedDistrict"
                                @schoolCount="updateSchoolCount($event)" @selectedSchool="changeSchool($event)"/>
          <!--        <h6>Participating School Count: {{ schoolCount }}</h6>-->
        </div>
      </div>
      <!--      <div>-->
      <!--        <button id="reset" class="button btn-primary" @click="clearResults">Reset Selected Organizations</button>-->
      <!--      </div>-->
    </div>
    <div class="row mt-2">
      <h5 style="color: black;">Date Range</h5>
      <div class="col-sm">
        <label>Start Date</label>
        <DatePicker v-model="startDate" :enable-time-picker="false" textInput/>
      </div>
      <div class="col-sm">
        <label></label>
        <p>The date range you search for will filter results between the two dates.</p>
      </div>
      <div class="col-sm">
        <label>End Date</label>
        <DatePicker v-model="endDate" :minDate="minEndDate" :enable-time-picker="false" textInput/>
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div>
        <AllParticipantGrowth
            :start-date="startDate"
            :end-date="endDate"
        />
      </div>
      <div v-if="showCountryData">
        <CountryParticipantGrowth
            :start-date="startDate"
            :end-date="endDate"
            :country-code="selectedCountry"
            :country-name="countryName"
        />
      </div>
      <div v-if="showStateData">
        <StateParticipantGrowth
            :start-date="startDate"
            :end-date="endDate"
            :state-name="stateName"
            :state-short="selectedState"
        />
      </div>
      <div v-if="showEsuData">
        <EsuParticipantGrowth
            :start-date="startDate"
            :end-date="endDate"
            :id-esu="selectedEsu"
        />
      </div>
      <div v-if="showDistrictData">
        <DistrictParticipantGrowth
            :start-date="startDate"
            :end-date="endDate"
            :id-district="selectedDistrict"
        />
      </div>
      <div v-if="showSchoolData">
        <SchoolParticipantGrowth
            :start-date="startDate"
            :end-date="endDate"
            :id-school="selectedSchool"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import {defaultEndDate, defaultStartDate, subtractDaysFromDate} from "@/Helpers/DateHelper";
import DatePicker from "@vuepic/vue-datepicker";
import ParticipatingSchools from "@/components/Reporting/OrganizationDropdowns/ParticipatingSchools";
import ParticipatingDistricts from "@/components/Reporting/OrganizationDropdowns/ParticipatingDistricts";
import ParticipatingEsus from "@/components/Reporting/OrganizationDropdowns/ParticipatingEsus";
import ParticipatingStates from "@/components/Reporting/OrganizationDropdowns/ParticipatingStates";
import ParticipatingCountries from "@/components/Reporting/OrganizationDropdowns/ParticipatingCountries";
import AllParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/AllParticipantGrowth";
import CountryParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/CountryParticipantGrowth";
import StateParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/StateParticipantGrowth";
import EsuParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/EsuParticipantGrowth";
import DistrictParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/DistrictParticipantGrowth";
import SchoolParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/SchoolParticipantGrowth";

export default {
  name: "SiteParticipantGrowth",
  components: {
    SchoolParticipantGrowth,
    DistrictParticipantGrowth,
    EsuParticipantGrowth,
    StateParticipantGrowth,
    CountryParticipantGrowth,
    AllParticipantGrowth,
    DatePicker,
    ParticipatingCountries,
    ParticipatingStates,
    ParticipatingEsus,
    ParticipatingDistricts,
    ParticipatingSchools
  },
  setup() {
    const startDate = ref(defaultStartDate());
    const endDate = ref(defaultEndDate());
    const selectedEsu = ref(-1);
    const selectedDistrict = ref(-1);
    const selectedSchool = ref(-1);
    const selectedState = ref("-1");
    const selectedCountry = ref("-1");
    const esuCount = ref(0);
    const districtCount = ref(0);
    const schoolCount = ref(0);
    const stateName = ref("");
    const countryName = ref("");

    onBeforeMount(() => {
    })

    const minEndDate = computed(() => {
      let min_date = new Date(startDate.value.toDateString());
      let result = min_date.setDate(min_date.getDate() + 1);
      return new Date(result);
    })

    function changeEsu(event) {
      selectedEsu.value = event;
      changeDistrict(-1);
    }

    function changeDistrict(event) {
      selectedDistrict.value = event;
      changeSchool(-1);
    }

    function changeSchool(event) {
      selectedSchool.value = event;
    }

    function changeState(event) {
      selectedState.value = event;
      changeEsu(-1);
    }

    function changeCountry(event) {
      selectedCountry.value = event;
      selectedState.value = "-1";
    }

    function updateEsuCount(event) {
      esuCount.value = event;
    }

    function updateDistrictCount(event) {
      districtCount.value = event;
    }

    function updateSchoolCount(event) {
      schoolCount.value = event;
    }

    function updateStateName(event) {
      stateName.value = event;
    }

    function updateCountryName(event) {
      countryName.value = event;
    }

    const showNebraskaHierarchy = computed(() => {
      return selectedState.value === 'NE';
    })

    const showCountryData = computed(() => {
      return selectedCountry.value !== 'ALL' && selectedCountry.value !== '-1';
    })

    const showStateData = computed(() => {
      return selectedState.value !== '-1';
    });

    const showEsuData = computed(() => {
      return selectedEsu.value !== -1;
    })

    const showDistrictData = computed(() => {
      return selectedDistrict.value !== -1;
    })

    const showSchoolData = computed(() => {
      return selectedSchool.value !== -1;
    })

    return {
      startDate,
      endDate,
      selectedEsu,
      selectedDistrict,
      selectedSchool,
      selectedState,
      esuCount,
      districtCount,
      schoolCount,
      selectedCountry,
      minEndDate,
      stateName,
      countryName,
      showNebraskaHierarchy,
      showCountryData,
      showSchoolData,
      showEsuData,
      showDistrictData,
      showStateData,
      updateStateName,
      updateCountryName,
      changeCountry,
      updateEsuCount,
      updateDistrictCount,
      updateSchoolCount,
      changeSchool,
      changeEsu,
      changeDistrict,
      changeState,
    }
  }
}
</script>

<style scoped>

</style>